import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
import LoginMethodsSnippet from '../../../snippets/login-methods-and-path-encoded.mdx';
import TestUsersSnippet from '../../../snippets/test-users.mdx';
export const _frontmatter = {
  "product": "verify",
  "category": "Integrations",
  "sort": 3,
  "title": "OneLogin",
  "subtitle": "This tutorial demonstrates how to integrate Criipto Verify with OneLogin"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This tutorial demonstrates how to integrate Criipto Verify with OneLogin. The following steps are required to complete your first login:`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#register-your-onelogin-tenant-in-criipto-verify"
        }}>{`Register your OneLogin tenant in Criipto Verify`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#configure-the-oauth2-flow"
        }}>{`Configure the OAuth2 flow`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#create-a-onelogin-trusted-idp-for-criipto-verify"
        }}>{`Create a OneLogin Trusted IDP for Criipto Verify`}</a></li>
    </ol>
    <h2>{`Register your OneLogin tenant in Criipto Verify`}</h2>
    <ol>
      <li parentName="ol">{`Login to you Criipto Verify account - `}<a parentName="li" {...{
          "href": "https://dashboard.criipto.com/applications/add"
        }}>{`https://dashboard.criipto.com/applications/add`}</a></li>
      <li parentName="ol">{`Navigate to Applications`}</li>
      <li parentName="ol">{`Select the `}<inlineCode parentName="li">{`+`}</inlineCode>{` sign to add a new application`}</li>
      <li parentName="ol">{`Provide the necessary information on the Register Application Screen`}</li>
    </ol>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`A name for the application. For this example OneLogin has been chosen.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Select the relevant domain from the `}<inlineCode parentName="p">{`Available on domain`}</inlineCode>{` section. This will be the domain used to communicate to OneLogin. If you only subscribe to one Criipto Verify domain, only one will be listed.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Add a Client ID/Realm to identify the OneLogin Tenant in Criipto Verify. In this example `}<inlineCode parentName="p">{`urn:onelogin:criipto`}</inlineCode>{` has been assigned.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Add the applicable Callback URL for you OneLogin tenant in the format of `}<inlineCode parentName="p">{`https://<SUBDOMAIN>.onelogin.com/access/idp`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Choose the relevant legal/bank identities you would like to integrate.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Once you are happy to proceed select `}<inlineCode parentName="p">{`Save`}</inlineCode>{`.`}</p>
        <p parentName="li">{`A completed application registration will look as follows:`}</p>
      </li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "680px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "93.22916666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAABYlAAAWJQFJUiTwAAABnUlEQVQ4y62T22rcMBCG/f6PlYveFUpLmzaQwyap17u2LOtk62DpLzNrLykEYpcKPqSRxK/RHKrvdzVeaoGH5zOeXjt8+3nA/cMLbu8OEL1ACg5+Mkzw9kMq8XiD/vCJoXXbPMIYA60kRqf5kp/sJjEWVPUXuPYHxu4W7vwVwZ4Qw4joySNz9W4rlVAOPibENCPOGTH6i+A/wB6Ok4eQGpMPAApKySil7CbnfBG0VoNwTsMYBe8tUqQX3S4oeSz4Wh/x+9igbhqepRQwWnJS9qCG/hLD46lFc+7QnDqcWhLT0DsxRqPtBMZRo1KkrgfQTC+sru8lhrVstIFUGv2gYKxFCB5zCkgp8LwVus+CbpygjGOksiCbMp3zvAO6n5YsG7m4a67z3mL+q7DPXcc9K/p+icX7ZRH8R6wxNCN/9Yp2ENKA9gftGKkcf2tTYbOxbi7MFJM3ds6X8/fGuk9xZ0H8p0HCi+CMeY4oJaHkN5TEmaOzTaxlc/P5Fy/arsUw9JBDz0kiW2m5O+vV/XPNgtQpxgyMXjrHWrW7Y/4AdDzDK84abygAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Add New App",
          "title": "Add New App",
          "src": "/static/e3a564d76fa5bd867775a51e455196fe/c5bb3/onelogin-add-new-application.png",
          "srcSet": ["/static/e3a564d76fa5bd867775a51e455196fe/8514f/onelogin-add-new-application.png 192w", "/static/e3a564d76fa5bd867775a51e455196fe/804b2/onelogin-add-new-application.png 384w", "/static/e3a564d76fa5bd867775a51e455196fe/c5bb3/onelogin-add-new-application.png 680w"],
          "sizes": "(max-width: 680px) 100vw, 680px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "680px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "76.56250000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABYlAAAWJQFJUiTwAAABKklEQVQ4y52TW0vEMBCF+/9/liCirFa8LKt0X9Ukzb3X9eHITLelrNSaPgyBZPJlZs5JZp2BUBKlLuG9RddGtE34V1CucwZSKb5Pe9nuuUC+PyIEO22mQvsuoqr8ALy62+Mmf0OMDs5bnLoK/Yao64C2jUOFDy8FrNXQRvMhvZoSdCdGj4YqvL4/4PbxnVuWpZoSxnUtxrzm3H62eyqQvx65ZWN0MvAyNzOWVFbQWsNa86vlNfg8hys0VuNLSm5XyME+FLSfAhxUjsgI9CkEnLOsMsFIHAKG4Fj1ebVLQFZ5BFKFND9qmQ7oteac0KcC6acQUOmSQd+nenHgfwEn2xDoQwj+dmN1W0SZbDOfIc9ug3UubDOoTGJQtapUDF+b3aJtlr5SirHn8QO3+oJ4BaQV9AAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Choose eID's",
          "title": "Choose eID's",
          "src": "/static/66d32d7b35f3c950b8337018773c517e/c5bb3/onelogin-choose-eids.png",
          "srcSet": ["/static/66d32d7b35f3c950b8337018773c517e/8514f/onelogin-choose-eids.png 192w", "/static/66d32d7b35f3c950b8337018773c517e/804b2/onelogin-choose-eids.png 384w", "/static/66d32d7b35f3c950b8337018773c517e/c5bb3/onelogin-choose-eids.png 680w"],
          "sizes": "(max-width: 680px) 100vw, 680px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h2>{`Configure the OAuth2 flow`}</h2>
    <ol>
      <li parentName="ol">{`Navigate to applications`}</li>
      <li parentName="ol">{`Select the application configured in the previous section`}</li>
      <li parentName="ol">{`Scroll down to the OpenID Connect application section and enable `}<inlineCode parentName="li">{`Enable OAuth2 Code Flow`}</inlineCode>{` by selecting the corresponding toggle:
`}<span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "420px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.9375%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAABT0lEQVQoz2WQa07bQBSFvbGuhhX1X9fALxZAWuBHSltFVdVAQCExdfx+zMt24sQOXzWDiVA70tE5c++dozPXaxqNv4lJspy8LFn9iXjexERpRlYUlFXlOE5zpJIc9g3drnbYd81/8EytuJz+5mIy4+bHgs+3c2Z3K349+Ey+zrn+fs+Xb3dMpnP8IGbot+xGw/fGb+z1hxbo2Hc1Q98yHFqO/RZbt4+tfhksdq532LcupeN3RqeE2ig+nV9xvwo5Dh1KS6QSKCXRTkukFK6mjRxZObbr+jepF0QJH84+8vMxwp4oyVgFEU/PIWGSsQ5it1erozRn6YeO7Z5trR9Tv33b63aGxXKNH2yojaAxglpXtI1Eq8JxbSqMLk/9V13R1rZn50q2rX5NaIVRBVJkCJFTlSllkSBFTpHHiCp39ywN3b0s0hGJ64lxrqmlM/wLth8Ob9r5OZMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Client secret on save",
            "title": "Client secret on save",
            "src": "/static/1c3d31ef8a014a4e2b7cb4613dbec798/d10fb/onelogin-client-secret-on-save.png",
            "srcSet": ["/static/1c3d31ef8a014a4e2b7cb4613dbec798/8514f/onelogin-client-secret-on-save.png 192w", "/static/1c3d31ef8a014a4e2b7cb4613dbec798/804b2/onelogin-client-secret-on-save.png 384w", "/static/1c3d31ef8a014a4e2b7cb4613dbec798/d10fb/onelogin-client-secret-on-save.png 420w"],
            "sizes": "(max-width: 420px) 100vw, 420px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Save`}</inlineCode></li>
      <li parentName="ol">{`The client secret will be displayed - This will be needed when configuring the Trusted IDP within OneLogin. Please note this is the only time the client secret will be displayed. Criipto only stores the client secret as a hashed value, meaning it can not be retrieved again once generated and stored.
`}<span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "420px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.541666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA9ElEQVQoz5VR2U4DMQzM//8eDyBataUUKIXNUW2SzTnIzmZZFYSEpZHH4yO2IlLKiCkj5wLiHTFmJNJWOtWVUkFWa11ARnnnA8Rm/47N4YLTWWF7uOD4KrF//sTj7ozjy8Da3cMJu6cP3G/foIxtA/G7iZQScs4M4mn2PY4pIYQ4b/lde4u+qdBa43o1MMZAK4VhGCClZE6ecuSVUgyqv+XU45xrA9Xc2BIS3nsGFXS+1pz3mKbpR85ay1sKCsZxXMS/ziKUUhqY5+ZnnQdaO2K9JZ+uNcd8utbLaZ2HEHjY+qEYIy/Dn0LBf7B8WibfQFqtBV9xf23ASmpPSQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "OAuth2 config",
            "title": "OAuth2 config",
            "src": "/static/472b94bfc31a23911e7e69b957d68fde/d10fb/oauth2-client-secret.png",
            "srcSet": ["/static/472b94bfc31a23911e7e69b957d68fde/8514f/oauth2-client-secret.png 192w", "/static/472b94bfc31a23911e7e69b957d68fde/804b2/oauth2-client-secret.png 384w", "/static/472b94bfc31a23911e7e69b957d68fde/d10fb/oauth2-client-secret.png 420w"],
            "sizes": "(max-width: 420px) 100vw, 420px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></li>
      <li parentName="ol">{`Leave remaining settings as default.`}</li>
    </ol>
    <h2>{`Create a OneLogin Trusted IDP for Criipto Verify`}</h2>
    <ol>
      <li parentName="ol">{`Authenticate to the OneLogin admin console with an appropriate administrative account.`}</li>
      <li parentName="ol">{`Navigate to Authentication -> Trusted IDPs
`}<span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "768px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.854166666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAHABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAUE/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAABqxQ3g//EABkQAAMAAwAAAAAAAAAAAAAAAAEDBAACFP/aAAgBAQABBQLnTlqhqyVCzP8A/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGxAAAgIDAQAAAAAAAAAAAAAAAQIAERIzQZH/2gAIAQEABj8C1r5BgtCuRSyAmf/EABgQAQADAQAAAAAAAAAAAAAAAAEAESFB/9oACAEBAAE/IYYJNNmJsjtqX2f/2gAMAwEAAgADAAAAEAAP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGRABAQEBAQEAAAAAAAAAAAAAAREAIUFR/9oACAEBAAE/EFGtPsYkiGELXLrnBp693//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "OAuth2 config",
            "title": "OAuth2 config",
            "src": "/static/159712c8edf7b5d304e7fcee1214fd8f/212bf/onelogin-trusted-idps.jpg",
            "srcSet": ["/static/159712c8edf7b5d304e7fcee1214fd8f/7809d/onelogin-trusted-idps.jpg 192w", "/static/159712c8edf7b5d304e7fcee1214fd8f/4ecad/onelogin-trusted-idps.jpg 384w", "/static/159712c8edf7b5d304e7fcee1214fd8f/212bf/onelogin-trusted-idps.jpg 768w", "/static/159712c8edf7b5d304e7fcee1214fd8f/5ef17/onelogin-trusted-idps.jpg 1152w", "/static/159712c8edf7b5d304e7fcee1214fd8f/ac99c/onelogin-trusted-idps.jpg 1536w", "/static/159712c8edf7b5d304e7fcee1214fd8f/62522/onelogin-trusted-idps.jpg 1560w"],
            "sizes": "(max-width: 768px) 100vw, 768px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
Please note: For additional information on OneLogin Trusted IDP please refer to the `}<a parentName="li" {...{
          "href": "https://onelogin.service-now.com/kb_view_customer.do?sysparm_article=KB0010318"
        }}>{`Trusted IDP knowledge base article`}</a></li>
      <li parentName="ol">{`Select `}<inlineCode parentName="li">{`New Trust`}</inlineCode>{` from the top right`}</li>
      <li parentName="ol">{`Provide an appropriate name.
Please Note: For each legal/bank identity that needs to be integrated with OneLogin, a separate Trusted IDP needs to be configured. For the purposes of this documentation NO Bank ID, will be used as the example.`}</li>
      <li parentName="ol">{`In the Login Options section, if you wish to represent this Trusted IdP as an authentication option on the tenant’s login page via an icon, then check Show in Login panel and provide a url to a suitable icon. (Note: websites typically host a “favicon.ico” file that could be used e.g. `}<a parentName="li" {...{
          "href": "https://www.onelogin.com/favicon.ico"
        }}>{`https://www.onelogin.com/favicon.ico`}</a>{`)
In the example of NO BankID, `}<a parentName="li" {...{
          "href": "https://www.bankid.com/en/_themes/bankid-www/img/logo1-default.svg"
        }}>{`https://www.bankid.com/en/_themes/bankid-www/img/logo1-default.svg`}</a>{` can be used
`}<span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "768px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAGABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAduiUH//xAAUEAEAAAAAAAAAAAAAAAAAAAAQ/9oACAEBAAEFAn//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAQ/9oACAEBAAY/An//xAAXEAEBAQEAAAAAAAAAAAAAAAAAAREx/9oACAEBAAE/IeJa1//aAAwDAQACAAMAAAAQ/D//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAaEAEAAgMBAAAAAAAAAAAAAAABABExcZGh/9oACAEBAAE/EC01XYjITT2f/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Show login option",
            "title": "Show login option",
            "src": "/static/8043a465fca5a8eb41a347a457fa8661/212bf/onelogin-show-login-option.jpg",
            "srcSet": ["/static/8043a465fca5a8eb41a347a457fa8661/7809d/onelogin-show-login-option.jpg 192w", "/static/8043a465fca5a8eb41a347a457fa8661/4ecad/onelogin-show-login-option.jpg 384w", "/static/8043a465fca5a8eb41a347a457fa8661/212bf/onelogin-show-login-option.jpg 768w", "/static/8043a465fca5a8eb41a347a457fa8661/5ef17/onelogin-show-login-option.jpg 1152w", "/static/8043a465fca5a8eb41a347a457fa8661/ac99c/onelogin-show-login-option.jpg 1536w", "/static/8043a465fca5a8eb41a347a457fa8661/ee732/onelogin-show-login-option.jpg 1549w"],
            "sizes": "(max-width: 768px) 100vw, 768px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></li>
      <li parentName="ol">{`In the Configurations section, enter the Issuer URL. This will be the domain chosen during the Criipto Verify signup process.`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`To review your domain in Criipto Veirfy navigate to Domains from the admin console and be sure to prefix `}<inlineCode parentName="li">{`https://`}</inlineCode></li>
    </ul>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`(OPTIONAL) The Email Domains field is used to automatically invoke this Trusted IdP when a user enters their email address at login time - if the email address is unrecognized, but belongs to one of the domains listed, then this TIdP will be invoked via an authentication request. This setting can be left blank if invoking this Trusted IDP from the OneLogin login panel is the preferred method.`}</li>
      <li parentName="ol">{`Check `}<inlineCode parentName="li">{`Sign Users into OneLogin`}</inlineCode>{` and deselect `}<inlineCode parentName="li">{`Sign Users into additional applications`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Scroll down to the protocol section select `}<inlineCode parentName="li">{`OIDC`}</inlineCode>{` from the dropdown`}</li>
      <li parentName="ol">{`Add `}<inlineCode parentName="li">{`{tidp.email}`}</inlineCode>{` in the User Attribute Value in the User Attribute section or whatever is applicable to your use case. This is the attribute used for User attribute matching.
`}<span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "768px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31.770833333333332%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAGABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3KAH/8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABBQJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAGRAAAgMBAAAAAAAAAAAAAAAAABEBIWFB/9oACAEBAAE/IYrg8Hh//9oADAMBAAIAAwAAABAAD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABkQAAIDAQAAAAAAAAAAAAAAAAARAVGRIf/aAAgBAQABPxDrKho9NHpp/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "User attribute",
            "title": "User attribute",
            "src": "/static/cb215e6643d7af33ebc036f2c0752a49/212bf/onelogin-user-attribute.jpg",
            "srcSet": ["/static/cb215e6643d7af33ebc036f2c0752a49/7809d/onelogin-user-attribute.jpg 192w", "/static/cb215e6643d7af33ebc036f2c0752a49/4ecad/onelogin-user-attribute.jpg 384w", "/static/cb215e6643d7af33ebc036f2c0752a49/212bf/onelogin-user-attribute.jpg 768w", "/static/cb215e6643d7af33ebc036f2c0752a49/6a068/onelogin-user-attribute.jpg 960w"],
            "sizes": "(max-width: 768px) 100vw, 768px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></li>
      <li parentName="ol">{`Select Email for User Attribute Mapping or the value applicable to your use case.`}</li>
      <li parentName="ol">{`(OPTIONAL) Allowed Email Domains is a whitelist. This allows the administrator to restrict the acceptable email domains for inbound identities. If empty, there is no restriction.`}</li>
      <li parentName="ol">{`The authentication endpoint syntax is as follows`}</li>
    </ol>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`https://<Criipto Verify Domain Name>/<base64 encoded acr value for legal/bank id being configured>/oauth2/authorize`}</inlineCode></li>
      <li parentName="ul">{`To identify what the base64 encoded acr value for legal/bank id being configured, please use `}<a parentName="li" {...{
          "href": "#loginmethods"
        }}>{`Supported login methods table`}</a></li>
      <li parentName="ul">{`Therefore, in this example of Norwegian BankID the following issuer will be used: `}<inlineCode parentName="li">{`https://onelogintest-test.criipto.id/dXJuOmdybjphdXRobjpubzpiYW5raWQ=/oauth2/authorize`}</inlineCode></li>
    </ul>
    <ol {...{
      "start": 14
    }}>
      <li parentName="ol">{`Select BASIC as the Token Endpoint Auth. Method.`}</li>
      <li parentName="ol">{`Add the relevant token endpoint. The Token Endpoint syntax would be: `}<inlineCode parentName="li">{`https://<Criipto Verify Domain Name>/oauth2/token`}</inlineCode></li>
      <li parentName="ol">{`Add the user information endpoint in the User Information Endpoint section. The user information endpoint syntax would be: `}<inlineCode parentName="li">{`https://<Criipto Verify Domain Name>/oauth2/userinfo`}</inlineCode></li>
      <li parentName="ol">{`Add the relevant scopes in the scopes section. At a minimum `}<inlineCode parentName="li">{`openid`}</inlineCode>{` must be used.`}</li>
      <li parentName="ol">{`Add the client ID specified in step 4 of the `}<a parentName="li" {...{
          "href": "#register"
        }}>{`Registration of the OneLogin tenant in Criipto Verify`}</a>{` to the Client Id section.`}</li>
      <li parentName="ol">{`Add the Client Secret in the Client Secret section as noted in step in step 6 of `}<a parentName="li" {...{
          "href": "#enable"
        }}>{`Configure the OAuth2 flow`}</a>{`.`}</li>
      <li parentName="ol">{`Lastly In the Trusted IdP Settings tab, head to the top of the page and check `}<inlineCode parentName="li">{`Enable Trusted IDP`}</inlineCode>{` in the Enable/Disable field.`}</li>
    </ol>
    <h2>{`Supported login methods`}</h2>

    <LoginMethodsSnippet mdxType="LoginMethodsSnippet" />
    <h2>{`Test users`}</h2>

    <TestUsersSnippet mdxType="TestUsersSnippet" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      